import React, { Fragment } from "react";
import styles from "./skeleton.css";

const Skeleton = () => {
    return (
        <Fragment>
            <div styleName={"styles.cardSkeleton"}>
                <div className="shimmer" styleName={"styles.carImage"} />
                <div>
                    <div className="shimmer" styleName={"styles.price"} />
                    <div className="shimmer" styleName={"styles.price"} />
                    <div className="shimmer" styleName={"styles.price"} />
                </div>
                <div className="shimmer" styleName={"styles.greyBar"} />
            </div>
        </Fragment>
    );
};

export default Skeleton;
