/* eslint-disable max-statements */
import React, {useEffect, useState, useCallback} from "react";
import styles from "./styles.css";
import { CAR_LIST_EVENT_NAMES, NUMBER, PAGE_SOURCE } from "../../../constants/app-constants";
import PropTypes from "prop-types";
import Filters from "../../../utils/filters-v2";
import { useHistory } from "react-router-dom";
import { getCity } from "../../../utils/helpers/get-city-from-listing";
import Slider from "react-slick";
import InventoryCarCardSkeleton from "../inventory-car-card/skeleton";
import ArrowIcon from "../../shared/arrow";
import InventoryCarCardRevamp from "../inventory-car-card-revamp";
import { trackDesktopCustomEventsAU} from "../../../tracking";
import { AMPLITUDE_EVENTS_CATEGORY} from "../../../tracking/amplitude-events";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";

const FilterByBodytypeSlider = ({
    bodyTypeContent = [],
    config = {},
    fetchCarListConnect,
    updateSelectedSuggestionsConnect,
    updateFilterOptionDataConnect,
    clearAllFiltersConnect,
    updateAppliedFiltersConnect,
    updateAppliedSuggestionsConnect,
    reloadCarListConnect,
    allFilters,
    metaContent = {},
    tradeInToggleSelected,
    userTradeInData
}) => {

    const { tradeIn = false } =  userTradeInData || {};

    const {suggestions = [], key: filterKey} = allFilters.bodyType || {};

    const history = useHistory();

    const [selectedbodyTypeFilter, setSelectedbodyTypeFilter] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [dragging, setDragging] = useState(false);

    const handleBeforeChange = useCallback(() => {
        setDragging(true);
    }, [setDragging]);

    const handleAfterChange = useCallback(() => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CARS_BY_BODYTYPE_CLICK, {
            eventLabel: "scroll_next"
        });
        setDragging(false);
    }, [setDragging]);

    const handleOnItemClick = useCallback(
        e => {
            if (dragging) {
                e.stopPropagation();
                e.preventDefault();
            }
        },
        [dragging]
    );

    const settings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        arrow: true,
        swipeToSlide: true,
        beforeChange: handleBeforeChange,
        afterChange: handleAfterChange
    };

    const fetchListing = async () => {
        const {code} = getCity(metaContent || {});
        const filters = new Filters({cityCode: code});
        const { payload } = filters.getListingPayload({
            selectedFilters: [selectedbodyTypeFilter],
            filters: allFilters
        });

        await fetchCarListConnect({
            size: 10,
            page: 0,
            ...(tradeIn && ({tradeIn: tradeInToggleSelected }))
        }, payload, "bodyTypeContent");
        setIsLoading(false);
    };

    const onClickCallback = (item) => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CARS_BY_BODYTYPE_CLICK, {
            eventLabel: item.appointmentId
        });
    };

    useEffect(() => {
        if (suggestions.length && !Object.keys(selectedbodyTypeFilter).length) {
            const { key: optionKey, displayText} = suggestions[0];
            setSelectedbodyTypeFilter({optionKey, displayText, filterKey});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suggestions]);

    useEffect(() => {
        if (Object.keys(selectedbodyTypeFilter).length) {
            fetchListing();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedbodyTypeFilter]);

    const handleOnTabClick = (suggestion) => {
        if (selectedbodyTypeFilter.optionKey === suggestion.key) {
            return;
        }
        setIsLoading(true);
        const { key: optionKey, displayText} = suggestion;
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CARS_BY_BODYTYPE_CLICK, {
            eventLabel: displayText
        });
        setSelectedbodyTypeFilter({optionKey, displayText, filterKey});
    };

    const handleOnViewAllClick = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CARS_BY_BODYTYPE_CLICK, {
            eventLabel: "view_all"
        });
        clearAllFiltersConnect();
        const data = {...selectedbodyTypeFilter, isSelected: true };
        updateSelectedSuggestionsConnect({...data, isSingleEntity: true, isSuggestion: true});
        updateFilterOptionDataConnect(data);
        updateAppliedFiltersConnect();
        updateAppliedSuggestionsConnect();
        reloadCarListConnect(true);
        const {code} = getCity(metaContent);
        const filters = new Filters({cityCode: code});
        const { relativeURL } = filters.getListingPayload({ selectedFilters: [selectedbodyTypeFilter], filters: allFilters });
        history.push(relativeURL);
    };

    return (
        <div className="container">
            <p styleName={"styles.heading"}>Used car by bodytype</p>
            <div styleName={"styles.tabContainer"}>
                <div styleName={"styles.bodyTypeTabWrapper"}>
                    { suggestions.map((suggestion, index) => (
                        <div styleName={"styles.tabWrapper"} key={index} onClick={handleOnTabClick.bind(null, suggestion)}>
                            <img src={config.imageHost + suggestion.image} />
                            <p styleName={`${ selectedbodyTypeFilter.optionKey === suggestion.key  ? "styles.activeTab" : "styles.tabName"}`}>{suggestion.displayText}</p>
                        </div>
                    ))
                    }
                </div>
                <p styleName={"styles.viewAllCars"} onClick={handleOnViewAllClick}>View All Cars<ArrowIcon blue={"BlueArrow"} rotateBy={-90} /></p>
            </div>
            <div styleName={"styles.sliderWrapper"}>
                <Slider {...settings}>
                    {isLoading ? Array(...new Array(NUMBER.FOUR)).map((i, index) => {
                        return (
                            <InventoryCarCardSkeleton key={index} />
                        );
                    }) :
                        bodyTypeContent.slice(0, NUMBER.TEN).map((item, index) => {
                            return (
                                <div key={index} onClickCapture={handleOnItemClick}>
                                    <InventoryCarCardRevamp
                                        onClickCallback = {onClickCallback}
                                        config={config}
                                        impressionSource={AMPLITUDE_EVENTS_CATEGORY.CARS_BY_BODYTYPE_SEEN}
                                        gtmFrom="home_page"
                                        defaultTootlipStyle="styles.top"
                                        index={index}
                                        item={item}
                                        egcRenderType="listing"
                                        bodyType={item.body || item.bodyType}
                                        ampImpressionSource={CAR_LIST_EVENT_NAMES.PAGE_HOME}
                                        ampPageSource={PAGE_SOURCE.HOME_PAGE}
                                        {...({...item, emiDetails: item.emiDetails || {}}) }
                                    />
                                </div>
                            );
                        })}
                </Slider>
            </div>
        </div>

    );
};

FilterByBodytypeSlider.propTypes = {
    bodyTypeContent: PropTypes.array,
    config: PropTypes.object,
    updateSelectedSuggestionsConnect: PropTypes.func,
    updateFilterOptionDataConnect: PropTypes.func,
    fetchCarListConnect: PropTypes.func,
    clearAllFiltersConnect: PropTypes.func,
    updateAppliedFiltersConnect: PropTypes.func,
    updateAppliedSuggestionsConnect: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    allFilters: PropTypes.object,
    metaContent: PropTypes.object,
    tradeInToggleSelected: PropTypes.bool,
    userTradeInData: PropTypes.object
};

export default FilterByBodytypeSlider;
