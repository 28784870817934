import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Skeleton from "../h1/skeleton";

const H2 = (props) => {
    const { text, color, isLoading, withUnderline = true, disableTextCapitalize = false } = props;
    let heading = (<h2 styleName={withUnderline ?  "styles.heading" : "styles.noUnderline"} style={{ color, textTransform: disableTextCapitalize ? "none" : "capitalize" }}>{text}</h2>);
    if (isLoading) {
        heading = <Skeleton />;
    }
    return heading;
};

H2.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    isLoading: PropTypes.bool,
    withUnderline: PropTypes.bool,
    disableTextCapitalize: PropTypes.bool
};

export default H2;
