import { ConfigService, ListingServiceAU } from "../../../service";
import { AU_HOMEPAGE_PAGINATION_SIZE, CURRENT_SALE } from "../../../constants/app-constants";

import Types from "./types";
import {getSaleConfigType} from "../../../utils/helpers/sale-config";
import { FINANCE_TYPE } from "../../../constants/checkout-constants";
import { ZERO_DP_VARIANTS } from "../../../constants/optimize-constants";

const fetchCarListInit = () => ({
    type: Types.FETCH_CAR_LIST
});

const fetchCarListSuccess = (data, params, contentKey) => ({
    type: Types.FETCH_CAR_LIST_SUCCESS,
    data,
    params,
    contentKey
});

const fetchCarListFailure = (error) => ({ type: Types.FETCH_CAR_LIST_FAILURE, error });

const fetchCarList = (params, queryString, contentKey) => (dispatch, getState) => {
    dispatch(fetchCarListInit());
    const {
        user: { secureToken, isUserZeroDpVariant }
    } = getState();
    if (queryString && queryString.indexOf("userFinanceType") < 0) {
        queryString = `${queryString}&userFinanceType=${FINANCE_TYPE.CARS24}`;
    }
    const cityCode = "AU_VIC_MEL";
    const defaultParams = {
        ...(cityCode && { sf: `city:${cityCode}` }),
        size: AU_HOMEPAGE_PAGINATION_SIZE,
        page: 0,
        sort: "releavance",
        userFinanceType: FINANCE_TYPE.CARS24,
        zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP
    };

    params = params ? {...params, zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP } : null;
    return new Promise((resolve, reject) => {
        ListingServiceAU.fetchListing(params || defaultParams, queryString, secureToken)
            .then((response) => {
                dispatch(fetchCarListSuccess(response.data, params, contentKey));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(fetchCarListFailure(error));
                reject(error);
            });
    });
};

const fetchConfigInit = () => ({
    type: Types.FETCH_CONFIG
});

const fetchConfigSuccess = (data, params) => ({
    type: Types.FETCH_CONFIG_SUCCESS,
    data,
    params
});

const fetchConfigFailure = (error) => ({ type: Types.FETCH_CONFIG_FAILURE, error });

const fetchConfig = (params) => (dispatch) => {
    dispatch(fetchConfigInit());
    const { type = CURRENT_SALE} = params || {};
    return new Promise((resolve, reject) => {
        ConfigService.fetchConfigDetails({type})
            .then((response = {}) => {
                let obj = {};
                const {responseCode = null, message = null, data = []} = response.data || {};
                const currentLiveSale = data.find(item => item.config.saleLive) || {};
                const currentLiveSaleData = currentLiveSale.config || {};
                obj = {responseCode, message, ...currentLiveSaleData};
                const configObj = getSaleConfigType(obj);
                dispatch(fetchConfigSuccess({...obj, ...configObj}));
                resolve(obj);
            })
            .catch((error) => {
                dispatch(fetchConfigFailure(error));
                reject(error);
            });
    });
};

const setCurrentScreen = (data) => ({
    type: Types.SET_LOGO_URL,
    data
});

const fetchTotalAvailableCarSuccess = (data) => ({
    type: Types.FETCH_TOTAL_CAR_SUCCESS,
    data
});
const fetchTotalAvailableCarFailure = () => ({
    type: Types.FETCH_TOTAL_CAR_FAILURE
});

const fetchTotalAvailableCar = (params) => async (dispatch) => {
    try {
        const response = await ListingServiceAU.getTotalAvailableCars(params);
        dispatch(fetchTotalAvailableCarSuccess(response.data));
    } catch (error) {
        fetchTotalAvailableCarFailure(error);
    }
};

const updateFinanceCentricAnimation = (data) => ({
    type: Types.UPDATE_FINANCE_CENTRIC_ANIMATION,
    data
});
export { fetchCarList, fetchConfig, setCurrentScreen, fetchTotalAvailableCar, updateFinanceCentricAnimation };
